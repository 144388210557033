<template>
    <div>
        <el-card>
            <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="注文番号:" prop="orderNo">
                            <el-input disabled v-model="form.orderNo" style="width: 55%">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="返金理由:" prop="refundReason" label-width="10%">
                            <el-input disabled rows="5" type="textarea" v-model="form.refundReason"
                                style="width:80%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.url.length > '0'">
                    <el-col :span="24">
                        <el-form-item label="返金理由画像:" label-width="10%">
                            <el-image :src="item" style="height: 150px;margin-right: 15px;" @click="chakandatu(item)"
                                v-for="(item, index) in form.url" :key="index">
                                <div slot="placeholder" class="image-slot">
                                    読み込み中<span class="dot">...</span>
                                </div>
                            </el-image>
                            <el-dialog :visible.sync="dialogVisible" append-to-body v-if="dialogVisible"
                                style="text-align: center">
                                <img height="400px" :src="url" alt="" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="text-align: center;">
                    <span>
                        <el-button @click="Quxiao">取消</el-button>
                        <el-button @click="DisagreeRefundOrder">返金を拒否</el-button>
                        <el-button type="primary" @click="AgreeRefundOrders">返金に同意</el-button>
                    </span>
                </el-row>

            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'ManagementGetOrdersRefunds',
    props: {
        orderId: {
            type: String,
            default: ''
        },
        orderStatus: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            dialogVisibleTK: false,
            refundRefusedReason: '',
            receivedProductsOrderTime: '',
            form: {
                orderNo: "",
                refundReason: "",
                url: [],
            },
            rules: {},
            url: '',
        };
    },
    created() {
    },
    mounted() {
        this.GetOrdersRefunds()
    },

    methods: {
        chakandatu(val) {
            this.dialogVisible = true
            this.url = val
        },
        async GetOrdersRefunds() {
            const { data: res } = await this.$http
                .post(this.$url.GetOrderDetail, 
                    {
                        accessToken: localStorage.getItem('authToken'),
                        currentRole: localStorage.getItem('currentRole'),
                        orderId: this.orderId
                    }
                )
            if (res.success == 'true') {
                this.form.orderNo = res.result[0].orderId
                this.form.refundReason = res.result[0].refundReason
                this.receivedProductsOrderTime = res.result[0].sendProductsTime
                if (res.result[0].refundPicIds != null) {
                    let arr = res.result[0].refundPicIds.split(',')
                    for (let i = 0; i < arr.length; i++) {
                        let url = this.$url.urls + arr[i]
                        this.form.url.push(url)
                    }
                }

            } else {
                this.$message.error(res.message)
            }
        },
        DisagreeRefundOrder(row) {
            this.$emit('submitNo');
        },
        AgreeRefundOrders() {
            this.$emit('submitYes');
        },
        Quxiao() {
            this.$emit('submitQX');
        },
        passFather() {
            return this.receivedProductsOrderTime;   //this.ruleForm是子组件填写的数据
        },
    },
};
</script>
<style lang="less" scoped>
.el-button1 {
    margin-top: 2px;
}

.el-card {
    margin-top: 15px;
}

textarea {
    height: 100px;
}
</style>

<template>
  <div>
   
    <el-card class="intanle" v-if="!istanchuang">
      <span class="h1">ECサイト注文管理リスト</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <!-- <span>作成日時：</span>
          <el-date-picker v-model="startTime" align="right" value-format="yyyy-MM-dd" type="date" placeholder="选择起始日期" :picker-options="pickerOptionsStart"> </el-date-picker>
          <span style="margin-left: 15px;">-</span>
          <el-date-picker v-model="endTime" align="right" value-format="yyyy-MM-dd" type="date" placeholder="选择结束日期" :picker-options="pickerOptionsEnd" style="margin-left: 15px;"> </el-date-picker>

          <span style="margin-left: 15px">订单编号：</span>
          <el-input v-model="orderNo" placeholder="请输入订单编号" style="width: 19%" maxlength="12"></el-input> -->
          <span style="margin-left: 15px">購入ユーザー：</span>
          <el-input v-model="userName" placeholder="購入ユーザーを選択してください" style="width: 19%"></el-input>

          <span style="margin-left: 15px">注文状態：</span>
          <el-select v-model="orderStatus" placeholder="注文状態を選択してください" style="width: 19%">
            <el-option v-for="item in orderStatuslist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <span style="float: right; z-index: 5; position: relative">
            <el-button style="margin-left: 20px;border: none" type="primary" @click="searchList">検索</el-button>
            <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
          </span>
          <br />
        </el-col>
        <!-- <el-col>
          <span style="margin-left: 15px">销售额：</span>
          <el-input v-model="actualTotalPayFrom" placeholder="请输入销售额范围" style="width: 220px"></el-input>
          <span style="margin-left: 15px;">-</span>
          <el-input v-model="actualTotalPayTo" placeholder="请输入销售额范围" style="margin:10px 0 0 15px; width: 220px"></el-input>
          <p v-if="!istanchuang">
            <span style="float: left">
              <el-button v-if="role == 1" @click="toinsert" type="primary" icon="el-icon-plus">新規</el-button>
            </span>
          </p>
        </el-col> -->
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="500px"
        @sort-change="sortChange"
        @current-change="selectThisLine"
        :highlight-current-row="istanchuang"
        ref="multipleTable"
        v-loading="loading"
        :data="orderList"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column label="注文番号" prop="orderNo" width="220" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.orderNo }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="注文番号" prop="orderNo" width="220" v-else></el-table-column>
        <el-table-column label="ユーザー名" prop="userName" width="120"></el-table-column>
        <el-table-column label="配送先住所" prop="addressContents">
          <template slot-scope="scope">
            <div style="text-align: left;">
              {{ scope.row.addressContents[0] }}
            </div>
            <div style="text-align: left;" v-if="scope.row.addressContents.length == 3">
              {{ scope.row.addressContents[2] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="実際の支払額" prop="actualTotalPay" width="100"></el-table-column>
        <el-table-column label="配送番号" prop="deliveryNo" width="180">
          <!-- <template slot-scope="scope"> -->
          <!-- <span v-if="scope.row.deliveryNo != ''">{{ scope.row.deliveryNo }}</span>
                                                            <span v-else>自提</span> -->
          <!-- </template> -->
        </el-table-column>
        <el-table-column label="注文状態" prop="orderStatus" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == '10'">未支払い</span>
            <span v-if="scope.row.orderStatus == '20'">支払い済み</span>
            <span v-if="scope.row.orderStatus == '30'">受注済み</span>
            <span v-if="scope.row.orderStatus == '40'">発送中</span>
            <span v-if="scope.row.orderStatus == '50'">受取済み</span>
            <span v-if="scope.row.orderStatus == '60'">注文完了</span>
            <span v-if="scope.row.orderStatus == '70'">返品申請中</span>
            <span v-if="scope.row.orderStatus == '80'">返金中</span>
            <span v-if="scope.row.orderStatus == '90'">返品返金完了</span>
            <span v-if="scope.row.orderStatus == '100'">取引終了</span>
            <!-- 注文完了、返品申請中、返金中、注文キャンセル状態では、管理画面での注文情報の修正はできません -->
          </template>
        </el-table-column>
        <!-- <el-table-column label="订单编号" prop="orderNo" width="150"></el-table-column> -->
        <el-table-column label="作成日時" prop="createTime" width="200" sortable="custom"></el-table-column>
        <el-table-column label="操作" width="250" v-if="!istanchuang">
          <template slot-scope="scope">
            <el-button
              @click.native.stop="handleupdate(scope.row)"
              type="text"
              size="small"
              v-if="role == 1 && scope.row.orderStatus != '60' && scope.row.orderStatus != '70' && scope.row.orderStatus != '80' && scope.row.orderStatus != '90' && scope.row.orderStatus != '100'"
              class="el-icon-edit"
              style="color: #1890ff"
              >編集
            </el-button>
            <el-button v-if="scope.row.orderStatus == '20'" @click.native.stop="ConfirmOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">店舗受注</el-button>

            <!-- 
                                                            <el-select style="display: inline-block;width: 90px;margin-left: 15px;margin-right: 15px;"
                                                                @visible-change="xuanzedeliveryCompany"
                                                                v-if="scope.row.orderStatus == '30' && scope.row.deliveryCompany == ''"
                                                                v-model="scope.row.deliveryCompany" placeholder="请选择">
                                                                <el-option v-for="item in deliveryCompanys" :key="item.value" :label="item.label"
                                                                    :value="item.value">
                                                                </el-option>
                                                            </el-select>
                                                            <el-button v-if="scope.row.orderStatus == '30' && scope.row.deliveryCompany != ''"
                                                                @click.native.stop="SendProducts(scope.row)" type="text" size="small" class="el-icon-edit"
                                                                style="color: #1890ff">订单发货({{ scope.row.deliveryCompany }})</el-button>
                                                            <el-tooltip v-if="scope.row.orderStatus == '30' && scope.row.deliveryCompany != ''" class="item"
                                                                effect="dark" content="重选快递" placement="top">
                                                                <el-button type="text" icon="el-icon-circle-close" @click="chongxuan(scope.row)">
                                                                </el-button>
                                                            </el-tooltip> -->

            <el-button v-if="scope.row.orderStatus == '30'" @click.native.stop="SendProducts(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">注文発送</el-button>
            <el-button v-if="scope.row.orderStatus == '20' || scope.row.orderStatus == '10'" @click.native.stop="CancelOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">注文キャンセル</el-button>
            <el-button v-if="scope.row.orderStatus == '10'" @click.native.stop="confirmOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">注文完了</el-button>
            <!-- <el-button v-if="scope.row.orderStatus == '50'" @click.native.stop="FinishOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">订单已完成</el-button> -->
            <el-button v-if="scope.row.orderStatus == '70' || scope.row.orderStatus == '60'" @click.native.stop="CloseOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">取引終了</el-button>
            <el-button v-if="scope.row.orderStatus == '70'" @click.native.stop="AgreeRefundOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">返金</el-button>
            <!-- <el-button @click.native.stop="RefundOrderCS(scope.row)" type="text" size="small"
                            class="el-icon-edit" style="color: #1890ff">退款(测试)</el-button> -->
            <!-- <el-button v-if="scope.row.orderStatus == '70'" @click.native.stop="AgreeRefundOrder(scope.row)"
                                                                type="text" size="small" class="el-icon-edit" style="color: #1890ff">同意退款</el-button>
                                                            <el-button v-if="scope.row.orderStatus == '70'"
                                                                @click.native.stop="DisagreeRefundOrder(scope.row)" type="text" size="small"
                                                                class="el-icon-edit" style="color: #1890ff">不同意退款</el-button> -->
            <!-- <el-button v-if="scope.row.orderStatus == '80'" @click.native.stop="RefundOrder(scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">订单退款</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
      <div style="clear:both;"></div>
      <el-dialog title="" :visible.sync="dialogVisible" width="80%" center v-if="dialogVisible">
        <GetOrdersRefundsVue :orderId="orderId" :orderStatus="orderStatus" ref="eidtList" @submitQX="Quxiao" @submitNo="DisagreeRefundOrder" @submitYes="AgreeRefundOrders"></GetOrdersRefundsVue>
        <!-- <span slot="footer" class="dialog-footer">
                                                        <el-button @click="Quxiao">取消</el-button>
                                                        <el-button @click="DisagreeRefundOrder">不同意退款</el-button>
                                                        <el-button type="primary" @click="AgreeRefundOrders">同意退款</el-button>
                                                    </span> -->
      </el-dialog>
      <!-- 返金を拒否する -->
      <el-dialog title="" :visible.sync="dialogVisibleTK" width="80%" center v-if="dialogVisibleTK">
        <el-form>
          <el-row>
            <el-col :span="24">
              <el-form-item label="拒否理由:" prop="refundRefusedReason" label-width="10%">
                <el-input rows="5" type="textarea" v-model="refundRefusedReason" style="width:80%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTK = false">取消</el-button>
          <el-button @click="DisagreeRefundOrders">確認</el-button>
          <!-- <el-button type="primary" @click="AgreeRefundOrders">同意退款</el-button> -->
        </span>
      </el-dialog>
      <el-dialog title="注文発送" :visible.sync="fahuoVisible" width="80%" center v-if="fahuoVisible">
        <!-- <GetOrdersRefundsVue :orderId="orderId"></GetOrdersRefundsVue> -->
        <el-form>
          <el-row>
            <el-col :span="12">
              <el-form-item label="配送方法:" prop="deliveryType">
                <el-select v-model="deliveryType" @change="iswaimai" placeholder="配送方法を選択してください" style="width: 55%">
                  <el-option v-for="item in deliveryTypelist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isziti">
              <el-form-item label="配送会社:" prop="deliveryCompany">
                <!-- <el-input placeholder="请输入物流公司" v-model="deliveryCompany" style="width: 55%">
                                                                    </el-input> -->
                <el-select v-model="deliveryCompany" placeholder="配送会社を選択してください" style="width: 55%">
                  <el-option v-for="item in deliveryCompanylist" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isziti">
              <el-form-item label="配送番号:" prop="deliveryNo">
                <el-input placeholder="配送番号を入力してください" v-model="deliveryNo" style="width: 55%"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="fahuoVisible = false">取消</el-button>
          <el-button type="primary" @click="fahuosave">確認</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import GetOrdersRefundsVue from './GetOrdersRefunds.vue'
export default {
  components: {
    GetOrdersRefundsVue
  },
  props: {
    istanchuang: {
      type: Boolean,
      default: false
    },
    refundReason: {}
  },
  data() {
    return {
      fahuoVisible: false,
      deliveryType: '',
      deliveryCompany: '',
      deliveryNo: '',
      isziti: false,
      deliveryTypelist: [
        { label: '宅配', value: '1' },
        { label: '店頭受取', value: '2' }
      ],
      orderId: '',
      dialogVisible: false,
      dialogVisibleTK: false,
      name: '',
      startTime: '',
      endTime: '',
      orderStatus: '',
      sortBy: '',
      refundRefusedReason: '',
      orderStatuslist: [
        { label: '未付款', value: '10' },
        { label: '支払い済み', value: '20' },
        { label: '受注済み', value: '30' },
        { label: '発送中', value: '40' },
        { label: '受取済み', value: '50' },
        { label: '注文完了', value: '60' },
        { label: '返品申請中', value: '70' },
        { label: '取引終了', value: '80' },
        // { label: '退款中', value: '80' },
        // { label: '退货退款结束', value: '90' },
        // { label: '交易关闭', value: '100' }
      ],
      userName: '',
      orderNo: '',
      deliveryCompanylist: [
        { label: '宅急便', value: '宅急便' }
      ],
      actualTotalPayFrom: '',
      actualTotalPayTo: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      orderList: [],
      loading: true,
      orderId: '',
      multipleSelection: [],
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.endTime) {
            return time.getTime() > new Date(this.endTime).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.startTime) {
            return time.getTime() < new Date(this.startTime).getTime() - 86400000
          }
        }
      }
    }
  },
  created() {
    this.role = window.localStorage.getItem('currentRole')
    // if (localStorage.getItem('updateState')) {
    //   var zph_management_params = JSON.parse(localStorage.getItem('zph_management_params'))
    //   this.startTime = zph_management_params.startTime
    //   this.endTime = zph_management_params.endTime
    //   this.orderStatus = zph_management_params.orderStatus
    //   this.userName = zph_management_params.userName
    //   this.orderNo = zph_management_params.orderNo
    //   this.actualTotalPayFrom = zph_management_params.actualTotalPayFrom
    //   this.actualTotalPayTo = zph_management_params.actualTotalPayTo
    //   this.currentPage = Number(zph_management_params.currentPage)
    //   this.pageSize = Number(zph_management_params.pageSize)
    //   localStorage.removeItem('updateState')
    //   localStorage.removeItem('zph_management_params')
    // }
    this.GetOrderList()
  },
  methods: {
    //  親コンポーネントのポップアップで選択されたデータ
    selectThisLine(row) {
      this.item = row
      // this.$parent.submitForm();
      this.$emit('submitForm')
      // return item
    },
    // 传给父级
    passFather() {
      return this.item //this.ruleForm是子组件填写的数据
    },
    iswaimai() {
      if (this.deliveryType == 1) {
        this.isziti = true
      } else {
        this.isziti = false
      }
    },
    chongxuan(row) {
      row.deliveryCompany = ''
      this.$forceUpdate()
    },
    xuanzedeliveryCompany(val) {
      if (!val) {
        this.$forceUpdate()
      }
    },
    // チェックボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
        this.orderId = val[0].orderId
      }
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetOrderList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetOrderList()
    },
    //リスト取得
    async GetOrderList() {
      /**
       * 
          startTime: this.startTime,
          endTime: this.endTime,
          orderStatus: this.orderStatus,
          userName: this.userName,
          orderNo: this.orderNo,
          actualTotalPayFrom: this.actualTotalPayFrom,
          actualTotalPayTo: this.actualTotalPayTo,
          sortBy: this.sortBy,
       */
      const { data: res } = await this.$http.post(this.$url.GetOrderList, {
          accessToken: localStorage.getItem('authToken'),
          currentRole: localStorage.getItem('currentRole'),
          merchantId: localStorage.getItem('merchantId'),
          orderStatus: this.orderStatus,
          userName: this.userName,
          currentPage: this.currentPage,
          pageSize: this.pageSize
      })
      if (res.success == 'true') {
        this.orderList = res.result
        for (let i = 0; i < this.orderList.length; i++) {
          this.orderList[i].addressContents = this.orderList[i].addressContent.split('<br>')
          this.orderList[i].deliveryCompany = ''
        }
        this.loading = false
        this.total = Number(res.maxRecords)
      } else {
        this.$message.error(res.message)
      }
    },
    //照会
    async searchList() {
      this.currentPage = 1
      this.GetOrderList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.local()
      this.$router.push({
        name: 'GetOrdersDetails',
        query: {
          orderId: row.orderId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(row) {
      this.local()
      this.$router.push({
        name: 'GetOrdersDetails',
        query: {
          orderId: row.orderId,
          type: 'update'
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.local()
      this.$router.push({
        name: 'GetOrdersDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.startTime = ''
      this.endTime = ''
      this.orderStatus = ''
      this.userName = ''
      this.orderNo = ''
      this.actualTotalPayFrom = ''
      this.actualTotalPayTo = ''
      this.currentPage = 1
      this.GetOrderList()
    },
    //1行削除
    handleDelete(row) {
      var data = [row.orderId]
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // const { data: res } = this.$http
          //     .delete(this.$url.DeleteOrder, {
          //         data: data,
          //         headers: {
          //             "Content-Type": "application/json;charset=UTF-8"
          //         }
          //     })
          // this.loading = false
          // if (res.msgCode == 1) {
          //     this.$message({
          //         type: "success",
          //         message: "削除成功!"
          //     })
          //     this.GetOrderList()
          // } else {
          //     this.$message({
          //         type: "error",
          //         message: res.message
          //     })
          // }
        })
        .catch(err => {
          // this.$message({
          //     type: "error",
          //     message: err
          // })
          this.GetOrderList()
        })
    },
    // 複数選択削除
    async handleDelete1() {
      var data1 = this.multipleSelection
      var data2 = []
      if (this.multipleSelection == undefined) {
        this.$confirm('データを選択してください', '表示', {
          type: 'warning'
        })
      } else {
        this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            for (var i = 0; i < data1.length; i++) {
              data2.push(data1[i].orderId)
            }
            this.$message({
              type: 'success',
              message: '削除成功!'
            })
            this.GetOrderList()
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err
            })
          })
      }
    },

    // async ConfirmOrder(row) {
    //   const { data: res } = await this.$http.get(this.$url.ConfirmOrder, {
    //     params: {
    //       orderId: row.orderId
    //     }
    //   })
    //   if (res.msgCode == 1) {
    //     this.$message.success('編集成功')
    //     this.GetOrderList()
    //   } else {
    //     this.$message.error(res.message)
    //   }
    // },
    async fahuosave() {
      if (this.deliveryCompany == '' && this.deliveryType == '1') {
        this.$message.error('配送会社を入力してください')
      } else if (this.deliveryNo == '' && this.deliveryType == '1') {
        this.$message.error('配送番号を入力してください')
      } else {
        const data1 = {
          
          accessToken: localStorage.getItem('authToken'),
          currentRole: localStorage.getItem('currentRole'),
          orderId: this.qurow.orderId,
          deliveryType: this.deliveryType,
          merchantId: this.$url.merchantId,
          deliveryCompany: this.deliveryCompany,
          deliveryNo: this.deliveryNo
        }
        const { data: res } = await this.$http.post(this.$url.SendProducts, data1, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        if (res.success == 'true') {
          this.fahuoVisible = false
          this.$message.success('発送成功')
          this.GetOrderList()
          // this.$forceUpdate();
        } else {
          this.$message.error(res.message)
        }
      }
    },
    async SendProducts(row) {
      this.deliveryType = row.deliveryType
      if (this.deliveryType == '1') {
        this.isziti = true
        this.qurow = row
        this.fahuoVisible = true
      } else {
        this.isziti = false
        const data1 = {
          accessToken: localStorage.getItem('authToken'),
          currentRole: localStorage.getItem('currentRole'),
          orderId: row.orderId,
          // deliveryType: row.deliveryType,
          // deliveryCompany: row.deliveryCompany,
          // deliveryNo: row.deliveryNo
        }
        const { data: res } = await this.$http.post(this.$url.SendProducts, data1, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
        if (res.success == 'true') {
          this.$message.success('発送成功')
          this.GetOrderList()
          // this.$forceUpdate();
        } else {
          this.$message.error(res.message)
        }
      }
    },
    async CancelOrder(row) {
      this.$confirm('この操作は注文をキャンセルします。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post(this.$url.CancelOrder, {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            orderId: row.orderId
        })
        if (res.success === 'true') {
          this.$message.success('取消成功')
          this.GetOrderList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async confirmOrder(row) {
      this.$confirm('この操作は注文を完了します。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post(this.$url.ConfirmOrder, {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            orderId: row.orderId
        })
        if (res.success === 'true') {
          this.$message.success('確認完了')
          this.GetOrderList()
        } else {
          this.$message.error(res.message)
        }
      })

    },
    async FinishOrder(row) {
      this.$confirm('この操作は注文を完了します。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.get(this.$url.FinishOrder, {
          params: {
            orderId: row.orderId
          }
        })
        if (res.msgCode == 1) {
          this.$message.success('注文が完了しました')
          this.GetOrderList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 暂时
    CloseOrder(row) {
      this.$confirm('この操作は注文を閉じます。続行しますか？', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.post(this.$url.ClosedOrder, {
          params: {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            orderId: row.orderId
          }
        })
        if (res.success === 'true') {
          this.$message.success('注文が閉じられました')
          this.GetOrderList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    Quxiao() {
      this.dialogVisible = false
    },
    async AgreeRefundOrder(row) {
      this.dialogVisible = true
      this.orderId = row.orderId
      this.orderStatus = row.orderStatus
    },
    DisagreeRefundOrder() {
      this.dialogVisibleTK = true
    },
    // 同意退款
    async AgreeRefundOrders() {
      // console.log(this.orderStatus)
      // const { data: res } = await this.$http.post(this.orderStatus == '70' ? this.$url.m_agreeRefundOrder : this.orderStatus == '80' ? this.$url.m_refundOrder80to90 : '', {
      const { data: res } = await this.$http.post(this.$url.m_agreeRefundOrder , {
        
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        orderId: this.orderId
      })
      if (res.success == 'true') {
        this.$message.success('返金に同意しました！')
        this.dialogVisible = false
        this.GetOrderList()
      } else {
        this.$message.error(res.message)
      }
    },
    async DisagreeRefundOrders() {
      this.dialogVisible = true
      const { data: res } = await this.$http.post(this.$url.m_disagreeRefundOrder, {
          accessToken: localStorage.getItem('authToken'),
          currentRole: localStorage.getItem('currentRole'),
          orderId: this.orderId,
          refundRefusedReason: this.refundRefusedReason
      })
      if (res.success == 'true') {
        this.$message.success('返金を拒否しました！')
        this.refundRefusedReason = ''
        this.dialogVisible = false
        this.dialogVisibleTK = false
        this.GetOrderList()
      } else {
        this.$message.error(res.message)
      }
    },
    async RefundOrder(row) {
      const { data: res } = await this.$http.get(this.$url.RefundOrder, {
        params: {
          orderId: row.orderId
        }
      })
      if (res.msgCode == 1) {
        this.$message.success('返金成功')
        this.GetOrderList()
      } else {
        this.$message.error(res.message)
      }
    },
    //リストのソート
    sortChange(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'o.createTime ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'o.createTime DESC'
        }
      }
      this.GetOrderList()
    },
    local() {
      var zph_management_params = {
        name: this.name,
        mobile: this.mobile,
        startTime: this.startTime,
        endTime: this.endTime,
        orderStatus: this.orderStatus,
        userName: this.userName,
        orderNo: this.orderNo,
        actualTotalPayFrom: this.actualTotalPayFrom,
        actualTotalPayTo: this.actualTotalPayTo,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }
      localStorage.setItem('zph_management_params', JSON.stringify(zph_management_params))
    },
    async RefundOrderCS(row) {
      const data = {
        orderId: row.orderId
      }
      const { data: res } = await this.$http.post(this.$url.RefundOrder1, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      if (res.msgCode == 1) {
        this.$message.success('返金成功')
        this.GetOrderList()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
